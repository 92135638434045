/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * devConsole is a configurable console for the application.
 * By default, the ebConsole logs nothing to the console.
 * By configuring the ebConsole, you can log to the console.
 *
 * Background:
 * - the existing EbDebug module provides a LogService and a SilentLogService
 *   and is fine to be used in places where dependency injection is possible.
 * - In all other areas of the application, the console is usually used directly.
 *   This is not possible with the EbDebug module, because the EbDebug module
 *   is not available in the application's root module.
 * - The ebConsole is a replacement for the console, which can be configured
 *   to log to the console or to log nothing.
 *
 * Apart from that, the ebConsole shows in the browser console the the line number and the file name
 * where the log was issued.
 *
 * And it may be easily configured in an application module like this:
 *  ebConsoleConfig.loggingType = 'ALL';
 * also in dependency on the environment:
 * if (environment.production) {
 *  ebConsoleConfig.loggingType = 'NONE';
 * } else {
 *  ebConsoleConfig.loggingType = 'ALL';
 * }
 *
 * And finally it allows for fast deployment in existing code base.
 */

/**
 * IDevConsole is the interface defining configurable console for the application.
 * - It provides functions to log to the console compatible to the console functions.
 */
export interface IDevConsole {
    /**
     * replacement for console.log()
     */
    log(...data: any[]): void;

    /**
     * replacement for console.info()
     */
    info(...data: any[]): void;
    /**
     * replacement for console.warn()
     */
    warn(...data: any[]): void;
    /**
     * replacement for console.error()
     */
    error(...data: any[]): void;

}

/**
 * DevConsoleSilent implements IDevConsole and an instance of this class is used as default console.
 * - It provides functions to log to the console compatible to the console functions.
 * - It does not log anything to the console.
 */
export class DevConsoleSilent implements IDevConsole {
    /**
     * replacement for console.log()
     */
    public log(...data: any[]): void {}

    /**
     * replacement for console.info()
     */
    public info(...data: any[]): void {}
    /**
     * replacement for console.warn()
     */
    public warn(...data: any[]): void {}
    /**
     * replacement for console.error()
     */
    public error(...data: any[]): void {}
}

/**
 * ebConsole is the global object - like console - to be used in the application for context aware logging.
 * - It provides functions to log to the console compatible to the console functions.
 * - It may be configured using the setConsole function.
 */

export let devConsole: IDevConsole = new DevConsoleSilent();

/**
 * Class DevConsoleConfig provides access to the configuration of the ebConsole.
 */
export class DevConsoleConfig {
    /**
     * loggingType defines the logging behavior of the ebConsole.
     * - 'ALL' means that the ebConsole logs to the console.
     * - 'NONE' means that the ebConsole does not log to the console.
     * The default mode is 'NONE'.
     */
    get loggingType(): 'ALL' | 'NONE' {
        return devConsole instanceof DevConsoleSilent ? 'NONE' : 'ALL';
    }
    /**
     * loggingType defines the logging behavior of the ebConsole.
     * - 'ALL' means that the ebConsole logs to the console.
     * - 'NONE' means that the ebConsole does not log to the console.
     */
    set loggingType(value: 'ALL' | 'NONE') {
        if (value === 'ALL') {
            devConsole = console;
        } else {
            devConsole = new DevConsoleSilent();
        }
    }
}

/**
 * and instance of DevConsoleConfig is used as default configuration for the ebConsole.
 */
export const devConsoleConfig = new DevConsoleConfig();