/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Optional } from '@angular/core';
import { DebugConfig, DebugLoggingType } from '../models/debug-config.model';
import { DEFAULT_LOGGING_TYPE } from './log.service.constants';
import { DevConsoleSilent } from '../models/dev-console';

/**
 * A logging service replacing console.x
 * with support for subject based logging and filtering
 */
@Injectable({
  providedIn: 'root'
})
export class LogService {

  private config: DebugConfig;

  constructor(
    @Optional() config?: DebugConfig
  ) {
    this.config = config ? {
      ...new DebugConfig(),
      ...config
    } : new DebugConfig();

    this.setLoggingType(this.config?.loggingType ?? DEFAULT_LOGGING_TYPE);
  }

  setLoggingType(loggingType: DebugLoggingType) {
    const actualConsole = loggingType === 'ALL' ? console : new DevConsoleSilent();

    this.log = actualConsole.log;
    this.info = actualConsole.info;
    this.warn = actualConsole.warn;
    this.error = actualConsole.error;
  }

  getLoggingTye(): DebugLoggingType {
    return this.config.loggingType;
  }

  /**
   * replacement for console.log()
   */
  public log(...data: any[]): void {
      console.log(...data);
  }

  /**
   * replacement for console.info()
   */
  public info(...data: any[]): void {
      console.info(...data);
  }
  /**
   * replacement for console.warn()
   */
  public warn(...data: any[]): void {
      console.warn(...data);
  }
  /**
   * replacement for console.error()
   */
  public error(...data: any[]): void {
      console.error(...data);
  }
}
