/**
 * type definition for logging features
 * - ALL: all messages will be logged by LogService
 * - NONE: no messages will be logged by LogService
 */
export type DebugLoggingType = 'ALL' | 'NONE';


/**
 * Configuration for class for debugging module
 */
export class DebugConfig {
    /**
     * whether template debugging component with selector "eb-debug" will
     * render debugging content or not.
     */
    templateDebugging?: boolean = false;

    /**
     * definition of logging type
     */
    loggingType?: DebugLoggingType = 'NONE';
}


